import {Component, OnInit} from '@angular/core';
import {AppService} from './app.service';
import {Source} from './class/Source';
import {SourceGroup} from './class/SourceGroup';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'newstrackerbackoffice';
  isLoading = false;

  constructor(private appService: AppService){}

  ngOnInit(): void {

  }
}
