import {AfterViewChecked, AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {TrackerHits} from '../class/Tracker';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnChanges {

  loading = true;
  elements: TrackerHits[];
  displayedColumns = ['status', 'timeStamp', 'applicationName', 'action', 'info'];

  @Input() collerationId: string;

  constructor(private appService: AppService, public datepipe: DatePipe) {

  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.loading = true;
    this.appService.searchByCorrelationId(this.collerationId).subscribe(res => {
      console.log(res);

      this.loading = false;
      this.elements = res.hits.hits;
    });
  }

  dateFormat(dateStr: Date): string {
    const date = new Date(dateStr);
    return this.datepipe.transform(date, 'dd/MM/YY HH:mm:ss');
  }

}
