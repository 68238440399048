import {Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {SearchResultsComponent} from '../search-results/search-results.component';
import {FormControl, FormGroup} from '@angular/forms';
import {SelectOptions} from '../class/SelectOptions';
import {AppService} from '../app.service';
import {SourceGroup} from '../class/SourceGroup';
import {Source} from '../class/Source';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  searchTxt = '';
  collerationId: string;
  //  collerationId: string = '50ce2e3f-afc0-43a1-839f-dbb676020fb4';
  sourcesGroup: SelectOptions[] = [];
  sourcesGroupCtrl = new FormControl();

  types: SelectOptions[] = [
    {valeur: 'Print', value: 'Print'},
    {valeur: 'Online', value: 'Online'},
    {valeur: 'Social', value: 'Social'},
    {valeur: 'Belga', value: 'Belga'},
    {valeur: 'Multimedia', value: 'Multimedia'},
    {valeur: 'Others', value: 'Others'},
  ];
  typesCtrl = new FormControl();

  providers: SelectOptions[] = [
    {valeur: 'Magazine', value: 'MAGAZINE'},
    {valeur: 'Newspaper', value: 'NEWSPAPER'},
    {valeur: 'Opoint', value: 'opoint'},
    {valeur: 'Print', value: 'print'},
    {valeur: 'Print Article', value: 'print-article'},
    {valeur: 'Print Page', value: 'print-page'},
    {valeur: 'Twitter', value: 'twitter'},
    {valeur: 'Website', value: 'WEBSITE'},
  ];
  providerCtrl = new FormControl();

  applicationNames: SelectOptions[] = [
    {valeur: 'Belgapress Kiosk', value: 'belgapressKiosk'},
    {valeur: 'Belgapress News', value: 'belgapressNews'},
    {valeur: 'Enricher', value: 'Enricher'},
    {valeur: 'Exporter', value: 'Exporter'},
    {valeur: 'Ftppush', value: 'Ftppush'},
    {valeur: 'Ingester', value: 'Ingester'},
    {valeur: 'Mule', value: 'Mule'},
    {valeur: 'Superdesk', value: 'Superdesk'},

  ];
  applicationNameCtrl = new FormControl();

  sources: SelectOptions[] = [
  ];
  sourcesCtrl = new FormControl();

  columns: SelectOptions[] = [];
  columnsCtrl = new FormControl();

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  status = new FormControl();
  exactString = new FormControl();


  @ViewChild(SearchResultsComponent) child: SearchResultsComponent;


  constructor(private appService: AppService, private activatedRoute: ActivatedRoute) {
    this.columns = this.appService.columns;
    this.activatedRoute.params.subscribe(params => {
      if (params['correlationID']) {
        this.searchTxt = params['correlationID'];
      }
    });
  }

  ngOnInit(): void {
    const defaultColumns = [];
    this.appService.defaultColumnsNumber.forEach(num => {
      defaultColumns.push(this.columns[num]);
    });
    this.columnsCtrl.setValue(defaultColumns);
    this.exactString.patchValue(true);
    this.appService.getSourcesGroup().subscribe((sourceGroup: SourceGroup[]) => {
      this.appService.sourcesGroup = sourceGroup;
      this.appService.sourcesGroup.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      this.appService.sourcesGroup.forEach(sg => {
        this.sourcesGroup.push({valeur: sg.name, value: sg.name});
      });
    });


  }


  doSearch(): void {
    console.log(this.child);
    this.collerationId = null;
    // jsp pk les dates font chier, 1h semble être retiré lors de la conversion
    let dateStart: string = null;
    let dateEnd: string = null;
    if (this.range.value.start !== null && this.range.value.end !== null) {
      const rangeDateStart = new Date(this.range.value.start);
      rangeDateStart.setHours(1);
      rangeDateStart.setMinutes(0);
      rangeDateStart.setSeconds(0);
      dateStart = rangeDateStart.toISOString();
      const rangeDateEnd = new Date(this.range.value.end);
      rangeDateEnd.setHours(24);
      rangeDateEnd.setMinutes(59);
      rangeDateEnd.setSeconds(59);
      dateEnd = rangeDateEnd.toISOString();
    }

    this.child.doSearch(this.columns,
      this.searchTxt,
      this.providerCtrl.value,
      this.applicationNameCtrl.value,
      this.sourcesCtrl.value,
      dateStart,
      dateEnd,
      this.status.value,
      this.exactString.value);
  }

  clearDate(): void {
    this.range.controls.start.setValue(null);
    this.range.controls.end.setValue(null);
  }

  removeFilters(): void {
    this.searchTxt = '';
    this.providerCtrl.reset();
    this.applicationNameCtrl.reset();
    this.sourcesCtrl.reset();
    this.sourcesGroupCtrl.reset();
    this.status.patchValue(false);
    this.exactString.patchValue(true);
  }

  onColumnsChange(): void {
    this.appService.defaultColumnsNumber = [];
    this.appService.columns.forEach((column, index) => {
      if (Object.values(this.columnsCtrl.value).indexOf(column) > -1) {
        this.appService.defaultColumnsNumber.push(index);
      }
    });
    this.child.changeColumnsAvailable();
  }

  setCorrelationId(correlationID: string): void {
    console.log(correlationID);
    this.collerationId = correlationID;
    setTimeout(() => {
      const el = document.getElementById('details');
      el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }, 0o0100);

  }

  onSourceGroupChange(): void {
    this.sourcesCtrl.reset();
    let sources: Source[] = [];
    this.sources = [];
    this.appService.getSources(this.sourcesGroupCtrl.value.value).subscribe((res: Source[]) => {
      sources = res;
      sources.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      sources.forEach(s => {
        this.sources.push({valeur: s.name, value: s.name});
      });
    });
  }

}



