export class ElasticSearch {
  query: {
    bool: {
      must: any[],
    },

  };
  aggs: {

  };
  collapse: {

  };
  sort: object;
  from: number;
  size: number;

  constructor(pageSize, pageNumber, sortDirection) {
    this.sort = {};
    this.query = {
      bool: {
        must: []
      }
    };
    this.aggs = {
      correlationID : {
        terms: {
          field: 'correlationID.keyword',
          size: pageSize,
          order: {timestampMax: 'desc'}
        },
        aggs: {
          correlationID: {
            terms: {
              field: 'correlationID.keyword',
              size: 1
            }
          },
          title: {
            terms: {
              field: 'title.keyword',
              size: 1
            }
          },
          provider: {
            terms: {
              field: 'provider.keyword',
              size: 1
            }
          },
          applicationName: {
            terms: {
              field: 'applicationName.keyword',
              size: 1
            }
          },
          source: {
            terms: {
              field: 'source.keyword',
              size: 1
            }
          },
          status: {
            terms: {
              field: 'status.keyword',
              size: 1
            }
          },
          timestampMax: {
            max: {
              field: 'timeStamp'
            }
          },
          pagenumber: {
            terms: {
              field: 'pageNumber',
              size: 1
            }
          }
        }
      }
    };
  }
}

/*
{
      "query":
        {
          "match":
            {
              "source.keyword": "Le Soir"
            }
        },
      "size": 5,
      "aggs":
        {
          "correlationID":
            {
              "terms":
                {
                  "field": "correlationID.keyword",
                  "size": 10
                },
              "aggs":
                {
                  "title":
                    {
                      "terms":
                        {
                          "field": "title.keyword",
                          "size": 1
                        }
                    },
                  "status":
                    {
                      "terms":
                        {
                          "field": "status.keyword",
                          "size": 1
                        }
                    },
                  "timestamp":
                    {
                      "terms":
                        {
                          "field": "timeStamp",
                          "size": 1
                        }
                    }
                }
            }
        }
    }
 */
