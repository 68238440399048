import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of as observableOf, of} from 'rxjs';
import {Tracker} from './class/Tracker';
import {SelectOptions} from './class/SelectOptions';
import DateTimeFormat = Intl.DateTimeFormat;
import {ElasticSearch} from './class/ElasticSearch';
import {Source} from './class/Source';
import {map} from 'rxjs/operators';
import {SourceGroup} from './class/SourceGroup';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  apiUrl = 'http://elastic-01-new.belga.be:9200/';
  pageSize = 30;
  columns = [
    {valeur: 'Status', value: 'status'},
    {valeur: 'Date', value: 'timeStamp'},
    {valeur: 'Page', value: 'pageNumber'},
    {valeur: 'Title', value: 'title'},
    {valeur: 'Provider', value: 'provider'},
    {valeur: 'Application Name', value: 'applicationName'},
    {valeur: 'Source', value: 'source'},
    {valeur: 'Correlation ID', value: 'correlationID'}
  ];
  defaultColumnsNumber = [0, 1, 2, 3];
  sources: Source[] = [];
  sourcesGroup: SourceGroup[] = [];

  constructor(private httpClient: HttpClient) { }

  getSourcesGroup(): Observable<any> {
    const apiUrl = 'http://belga-sourcetree-01.belga.be:8080/belga-sourcetree/sourceGroups';

    return this.httpClient.get(apiUrl);
  }

  getSources(source: string): Observable<any> {
    const apiUrl = 'http://belga-sourcetree-01.belga.be:8080/belga-sourcetree/sources?sourceGroups=' + source;
    console.log(apiUrl);

    return this.httpClient.get(apiUrl);
  }

  search(searchTxt: string,
         pageIndex: number,
         pageSize: number,
         sortActive: string,
         sortDirection: string,
         providers: SelectOptions[],
         applicationNames: SelectOptions[],
         sources: SelectOptions[],
         dateStart: string,
         dateEnd: string,
         showOnlyError: boolean,
         exactString: boolean): Observable<Tracker> {

    // mets tous les match dans un tableau
    const matchProviders = [];
    const matchApplicationNames = [];
    const matchSources = [];
    if (providers) {
      providers.forEach(provider => {
        matchProviders.push({ match_phrase: { provider: '"' + provider.value + '"' } }, );
      });
    }
    if (applicationNames) {
      applicationNames.forEach(applicationName => {
        matchApplicationNames.push({ match_phrase: { applicationName: '"' + applicationName.value + '"' } }, );
      });
    }
    if (sources) {
      sources.forEach(source => {
        matchSources.push({ match_phrase: { 'source.keyword': source.value } }, );
      });
    }


    // construction du json
    const json: ElasticSearch = new ElasticSearch(pageSize, pageIndex * pageSize, sortDirection);
    json.sort[sortActive] = {order: sortDirection};
    json.from = pageIndex * pageSize;
    json.size = 0;
    if (matchProviders.length > 0) {
      json.query.bool.must.push({
        bool: {
          should: matchProviders
        },
      });
    }
    if (matchSources.length > 0) {
      json.query.bool.must.push({
        bool: {
          should: matchSources
        },
      });
    }
    if (matchApplicationNames.length > 0) {
      json.query.bool.must.push({
        bool: {
          should: matchApplicationNames
        },
      });
    }
    if (dateStart !== null && dateEnd !== null) {
      json.query.bool.must.push({
        range: {
          timeStamp : {
            gte: dateStart,
            lte: dateEnd
          }
        }
      });
    }
    if (searchTxt !== '') {
      if (exactString) {
        searchTxt = '"' + searchTxt + '"';
      }
      json.query.bool.must.push({ query_string: { query:  searchTxt } });
    }
    if (showOnlyError) {
      json.query.bool.must.push({ match: {status: 'ERROR'} });
    }
    console.log(json);
    return this.httpClient.post<Tracker>(this.apiUrl + 'tracker/_search', json);
  }

  searchByCorrelationId(correlationId: string): Observable<Tracker> {
    correlationId = '"' + correlationId + '"';
    const json = {
      query: {
        match_phrase: { correlationID: correlationId }
      },
      size: 100,
      sort: {timeStamp: 'asc'}
    };
    return this.httpClient.post<Tracker>(this.apiUrl + 'tracker/_search', json);
  }
}
