<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item d-none d-sm-inline-block">
      <a href="/" class="nav-link">News Tracker backoffice</a>
    </li>
  </ul>

  <!-- Right navbar links -->
  <!--<ul class="navbar-nav ml-auto">
    <li class="nav-item">
      News Tracker BackOffice
    </li>
  </ul>-->
</nav>
<!-- /.navbar -->
