<div class="card">
  <div class="card-body">
    <h2>Search & filters</h2>
    <form class="form-inline" id="search">
        <div class="input-group input-group-sm">
          <input class="form-control" [ngClass]="{'form-control-navbar' : searchTxt != ''}" name="searchTxt" placeholder="Search" aria-label="Search" [(ngModel)]="searchTxt">
          <div class="input-group-append">
            <button class="btn btn-navbar" type="button" data-widget="navbar-search" *ngIf="searchTxt != ''" (click)="searchTxt = ''">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>


      <div id="filters">
        <div class="filters-title">
          Filters :
        </div>


        <mat-form-field appearance="fill">
          <mat-label>Content Type</mat-label>
          <mat-select [formControl]="typesCtrl" multiple>
            <mat-option *ngFor="let type of types" [value]="type">{{type.valeur}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!--<mat-form-field appearance="fill">
          <mat-label>Provider</mat-label>
          <mat-select [formControl]="providerCtrl" multiple>
            <mat-option *ngFor="let provider of providers" [value]="provider">{{provider.valeur}}</mat-option>
          </mat-select>
        </mat-form-field>-->

        <mat-form-field appearance="fill">
          <mat-label>ApplicationName</mat-label>
          <mat-select [formControl]="applicationNameCtrl" multiple>
            <mat-option *ngFor="let applicationName of applicationNames" [value]="applicationName">{{applicationName.valeur}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Sources Group</mat-label>
          <mat-select [formControl]="sourcesGroupCtrl" (selectionChange)="onSourceGroupChange()">
            <mat-option *ngFor="let source of sourcesGroup" [value]="source">{{source.valeur}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Source</mat-label>
          <mat-select [formControl]="sourcesCtrl" multiple>
            <mat-option *ngFor="let source of sources" [value]="source">{{source.valeur}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker-toggle matSuffix (click)="clearDate()" *ngIf="range.value.start != null || range.value.end != null">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

        </mat-form-field>

        <mat-slide-toggle style="margin-right: 10px" color="accent" [formControl]="status">Only error</mat-slide-toggle>

        <mat-slide-toggle color="accent" [formControl]="exactString">Exact search</mat-slide-toggle>

        <div class="divider">
          <hr>
        </div>

        <div class="filters-title">
          Display :
        </div>

        <mat-form-field appearance="fill">
          <mat-label>Columns</mat-label>
          <mat-select [formControl]="columnsCtrl" multiple (selectionChange)="onColumnsChange()">
            <mat-option *ngFor="let column of columns" [value]="column">{{column.valeur}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="divider">
          <hr>
        </div>

        <div class="filters-right">
          <button class="btn btn-info" (click)="doSearch()">
            <i class="fas fa-search"></i> Search
          </button>
        </div>

        <button class="btn btn-default remove-filters" (click)="removeFilters()"><i class="fas fa-times"></i></button>

      </div>

    </form>
    <hr>
  </div>
</div>

<div class="card">
   <div class="card-body">
     <h2>List of results</h2>
      <app-search-results (setCorrelationId)="setCorrelationId($event)"></app-search-results>
  </div>
</div>

<div class="card" *ngIf="collerationId != null" id="details">
  <div class="card-body">
    <h2>More details</h2>
    <app-details [collerationId]="collerationId"></app-details>
  </div>
</div>
