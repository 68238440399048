import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild, ViewChildren
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ExtractedData, TrackerHits} from '../class/Tracker';
import {AppService} from '../app.service';
import {SelectOptions} from '../class/SelectOptions';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [];

  resultsLength = 0;
  isLoadingResults = false;
  searchError = false;
  tackersHits: TrackerHits[] = [];
  extractedData: ExtractedData[] = [];
  searchTxt: string;
  pageSize = 0;
  firstSearch = true;
  providers: SelectOptions[] = [];
  applicationNames: SelectOptions[] = [];
  sources: SelectOptions[] = [];
  dateStart: string;
  dateEnd: string;
  showOnlyError: boolean;
  exactString: boolean;
  isSearchAsk = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() setCorrelationId = new EventEmitter<string>();

  constructor(private httpClient: HttpClient, private appService: AppService, public datepipe: DatePipe, private activatedRoute: ActivatedRoute) {
    this.displayedColumns.push('show');
    this.appService.defaultColumnsNumber.forEach(num => {
      this.displayedColumns.push(this.appService.columns[num].value);
    });
    this.activatedRoute.params.subscribe(params => {
      if (params['correlationID']) {
        this.searchTxt = params['correlationID'];
      }
    });
  }

  ngOnInit(): void {
    if (this.searchTxt !== undefined) {
      setTimeout(() => {
        this.doSearch([], this.searchTxt, [], [], [], null, null, false, true);
      }, 0);
    }
  }

  doSearch(columns: SelectOptions[],
           searchTxt: string,
           providers: SelectOptions[],
           applicationNames: SelectOptions[],
           sources: SelectOptions[],
           dateStart: string,
           dateEnd: string,
           showOnlyError: boolean,
           exactString: boolean): void {

    this.isSearchAsk = true;

    this.providers = providers;
    this.applicationNames = applicationNames;
    this.sources = sources;
    this.searchTxt = searchTxt;
    this.dateStart = dateStart;
    this.dateEnd = dateEnd;
    this.isLoadingResults = true;
    this.firstSearch = false;
    this.showOnlyError = showOnlyError;
    this.exactString = exactString;
    this.appService.search(
      searchTxt,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.providers,
      this.applicationNames,
      this.sources,
      this.dateStart,
      this.dateEnd,
      this.showOnlyError,
      this.exactString)
      .subscribe(res => {
      console.log(res);
      // this.resultsLength = res.hits.total.value;
      this.resultsLength = res.aggregations.correlationID.sum_other_doc_count;
      // this.tackersHits = res.hits.hits;
      this.extractedData = [];
      res.aggregations.correlationID.buckets.forEach(bucket => {
        this.extractedData.push({
          correlationID: bucket.correlationID.buckets[0]?.key,
          title: bucket.title.buckets[0]?.key,
          provider: bucket.provider.buckets[0]?.key,
          applicationName: bucket.applicationName.buckets[0]?.key,
          source: bucket.source.buckets[0]?.key,
          pageNumber: bucket.pagenumber.buckets[0]?.key,
          timeStamp: bucket.timestampMax.value_as_string,
          status: bucket.status.buckets[0]?.key
        });
      });
      console.log(this.extractedData);
      this.isLoadingResults = false;
    });
  }

  ngAfterViewInit(): void {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          if (this.firstSearch) {
            console.log('first search');
            //console.log(this.paginator);

            return [];
          }
          this.isLoadingResults = true;
          return this.appService.search(
            this.searchTxt,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            this.providers,
            this.applicationNames,
            this.sources,
            this.dateStart,
            this.dateEnd,
            this.showOnlyError,
            this.exactString
            /*this.sort.active, this.sort.direction, this.paginator.pageIndex*/);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.searchError = false;
          // this.resultsLength = data.hits.total.value;
          this.resultsLength = data.aggregations.correlationID.sum_other_doc_count;


          return data.aggregations.correlationID.buckets;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.searchError = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.extractedData = [];
        data.forEach(bucket => {
          this.extractedData.push({
            correlationID: bucket.correlationID.buckets[0]?.key,
            title: bucket.title.buckets[0]?.key,
            provider: bucket.provider.buckets[0]?.key,
            applicationName: bucket.applicationName.buckets[0]?.key,
            source: bucket.source.buckets[0]?.key,
            pageNumber: bucket.pagenumber.buckets[0]?.key,
            timeStamp: bucket.timestampMax.value_as_string,
            status: bucket.status.buckets[0]?.key
          });
        });
        console.log(this.extractedData);
    });

  }

  dateFormat(dateStr: Date): string {
    const date = new Date(dateStr);
    date.setHours(date.getHours() - ((date.getTimezoneOffset() / 60) * -1));
    return this.datepipe.transform(date, 'dd/MM/YY HH:mm:ss');
  }

  showTitle(row: ExtractedData): string {
    if (row.title && row.title.length > 100) {
      return row.title.slice(0, 100) + '...';
    } else {
      return row.title;
    }
  }

  changeColumnsAvailable(): void {
    this.displayedColumns = [];
    this.displayedColumns.push('show');
    this.appService.defaultColumnsNumber.forEach(num => {
      this.displayedColumns.push(this.appService.columns[num].value);
    });
  }

  goToElement(correlationID: string): void {
    this.setCorrelationId.emit(correlationID);
  }


}
