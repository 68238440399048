<ng-container *ngIf="!loading; else loader">

  <div class="infos">
    <div>
      <span class="title">Correlation Id : </span>
      {{elements[0]?._source.correlationID}}
    </div>
    <div>
      <span class="title">Title : </span>
      {{elements[0]?._source.title}}
    </div>
  </div>

  <div class="example-table-container table-responsive" style="margin-top: 10px;">
    <table mat-table [dataSource]="elements" class="example-table"
           matSort matSortActive="timeStamp" matSortDisableClear matSortDirection="asc">

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">
          <i class="fas fa-circle" style="color: green" *ngIf="row._source.status == 'OK'"></i>
          <i class="fas fa-circle" style="color: red" *ngIf="row._source.status == 'ERROR'"></i>
        </td>
      </ng-container>

      <!-- Timestamp Column -->
      <ng-container matColumnDef="timeStamp" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let row">
          {{dateFormat(row._source.timeStamp)}}
        </td>
      </ng-container>

      <!-- ApplicationName Column -->
      <ng-container matColumnDef="applicationName">
        <th mat-header-cell *matHeaderCellDef >Application</th>
        <td mat-cell *matCellDef="let row">{{row._source.applicationName}}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let row">{{row._source.action}}</td>
      </ng-container>

      <!-- Info Column -->
      <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef>Info</th>
        <td mat-cell *matCellDef="let row">{{row._source.info}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</ng-container>

<ng-template #loader>
  <div class="loader">
    <mat-spinner [diameter]="50" style="margin: auto"></mat-spinner>

  </div>
</ng-template>
