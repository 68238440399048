<div class="example-loading-shade"
     *ngIf="isLoadingResults || searchError">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  <div class="example-rate-limit-reached" *ngIf="searchError">
    There is a problem with your query
  </div>
</div>

<div class="example-table-container table-responsive">
  <table mat-table [dataSource]="extractedData" class="example-table"
         matSort matSortActive="timeStamp" matSortDisableClear matSortDirection="desc">

    <!-- Show Column -->
    <ng-container matColumnDef="show">
      <th mat-header-cell *matHeaderCellDef>Show</th>
      <td mat-cell *matCellDef="let row" (click)="goToElement(row.correlationID)">
        <i class="fas fa-search-plus"></i>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let row">
        <i class="fas fa-circle" style="color: green" *ngIf="row.status == 'OK'"></i>
        <i class="fas fa-circle" style="color: red" *ngIf="row.status == 'ERROR'"></i>
      </td>
    </ng-container>

    <!-- Timestamp Column -->
    <ng-container matColumnDef="timeStamp" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let row">
        {{dateFormat(row.timeStamp)}}
        <!--{{row.timeStamp | date:'dd/MM/yyyy HH:mm:ss':'pt'}}-->
      </td>
    </ng-container>

    <!-- Page Column -->
    <ng-container matColumnDef="pageNumber" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Page</th>
      <td mat-cell *matCellDef="let row">
        {{row.pageNumber}}
      </td>
    </ng-container>

    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let row">
        {{showTitle(row)}}
      </td>
    </ng-container>

    <!-- Provider Column -->
    <ng-container matColumnDef="provider">
      <th mat-header-cell *matHeaderCellDef>Provider</th>
      <td mat-cell *matCellDef="let row">{{row.provider}}</td>
    </ng-container>

    <!-- ApplicationName Column -->
    <ng-container matColumnDef="applicationName">
      <th mat-header-cell *matHeaderCellDef >applicationName</th>
      <td mat-cell *matCellDef="let row">{{row.applicationName}}</td>
    </ng-container>

    <!-- Source Column -->
    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef>source</th>
      <td mat-cell *matCellDef="let row">{{row.source}}</td>
    </ng-container>

    <!-- SubSource Column -->
    <!--<ng-container matColumnDef="subSource">
      <th mat-header-cell *matHeaderCellDef>subSource</th>
      <td mat-cell *matCellDef="let row">{{row._source.subSource}}</td>
    </ng-container>-->

    <!-- Source Column -->
    <ng-container matColumnDef="correlationID">
      <th mat-header-cell *matHeaderCellDef>Correlation ID</th>
      <td mat-cell *matCellDef="let row">{{row.correlationID}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 30, 50, 100]" [length]="resultsLength" [pageSize]="30"></mat-paginator>
